import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTaskAttribValues } from "../../../app/features/taskFormSlice/taskFormSlice";
import Autocomplete from "@mui/material/Autocomplete";
import { getSageObjectNames } from "../../../api/others";

/**
 * Component fetches dropdpown options from server and renders the object names available
 */
const SageDropdown = () => {
  const [objectNames, setObjectNames] = useState([]);

  // redux state
  const { taskAttribValues, selectedConnection, connections } = useSelector(
    (state) => state.taskForm
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const getWorkspaces = async () => {
      const connectionName = connections.find(
        (el) => el._id === selectedConnection
      )?.name;
      const { data } = await getSageObjectNames(connectionName);

      setObjectNames(data);
    };

    getWorkspaces();
  }, [selectedConnection]);

  return (
    <Grid item>
      {
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Autocomplete
              name="sageObjectName"
              disablePortal
              options={objectNames}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Object Name" />
              )}
              value={taskAttribValues.sageObjectName}
              onChange={(e, newValue) => {
                const obj = {};
                console.log("e.", e.target.name);
                obj["sageObjectName"] = newValue;
                dispatch(
                  setTaskAttribValues({
                    ...taskAttribValues,
                    ...obj,
                  })
                );
              }}
              required
            />
          </Grid>
        </Grid>
      }
    </Grid>
  );
};

export default SageDropdown;
