import { http } from "./https";
const { get } = http;

export const uploadFile = async (payload) => {
  const response = await http.post(`upload-file`, payload, {});
  return response;
};

export const getSageObjectNames = async (connectionName) => {
  const response = await get(`sage-metadata/${connectionName}/objects`);
  return response;
};

export const getAnaplanWorkspaces = async (connectionName) => {
  const response = await get(`anaplan-metadata/${connectionName}/workspaces`);
  return response;
};
