import { AddCircleOutlined, RemoveCircleOutlined } from "@mui/icons-material";
import { Grid, IconButton, TextField } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import {
  addColumnNamesAndValuesRow,
  deleteColumnNamesAndValuesRow,
  setColumnNamesAndValues,
} from "../../../app/features/taskFormSlice/taskFormSlice";
import { useSelector } from "react-redux";

const AddColumnsAndValues = () => {
  const dispatch = useDispatch();
  const taskFormState = useSelector((state) => state.taskForm);
  const { addColumnNamesAndValues } = taskFormState;

  const handleAddClick = () => {
    dispatch(addColumnNamesAndValuesRow());
  };

  const handleDeleteClick = () => {
    dispatch(deleteColumnNamesAndValuesRow());
  };

  const handleChange = (idx, name, value) => {
    dispatch(setColumnNamesAndValues({ idx, name, value }));
  };
  return (
    <>
      {addColumnNamesAndValues.map((el, idx) => {
        return (
          <Grid item style={{ display: "flex" }} sx={12}>
            <Grid container spacing={1}>
              <Grid item>
                <TextField
                  required
                  name="columnName"
                  label="Column Name"
                  value={el.columnName}
                  onChange={(e) =>
                    handleChange(idx, e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  name="columnValue"
                  label="Column Value"
                  fullWidth
                  value={el.columnValue}
                  onChange={(e) =>
                    handleChange(idx, e.target.name, e.target.value)
                  }
                />
              </Grid>
              {idx === addColumnNamesAndValues.length - 1 && (
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  {idx !== 0 && (
                    <IconButton onClick={handleDeleteClick}>
                      <RemoveCircleOutlined />
                    </IconButton>
                  )}
                  <IconButton onClick={handleAddClick}>
                    <AddCircleOutlined />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default AddColumnsAndValues;
