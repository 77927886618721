import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  description: "",
  addTaskLoader: false,
  taskTypes: [],
  taskTypeAttributes: [],
  selectedTaskId: "",
  taskTypeName: "",
  connections: [],
  selectedConnection: "",
  taskAttribValues: {},
  gSheetFileAndRangValue: [{ Sheet: "", File: "", rowindex: "", colindex: "" }],
  gSheetRangeAndValue: [{ range: "", value: "" }],
  addColumnNamesAndValues: [{ columnName: "", columnValue: "" }],
};

export const taskFormSlice = createSlice({
  name: "taskForm",
  initialState,
  reducers: {
    setTaskName: (state, action) => {
      state.name = action.payload;
    },

    setTaskDescription: (state, action) => {
      state.description = action.payload;
    },

    setAddTaskLoader: (state, action) => {
      state.addTaskLoader = action.payload;
    },

    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setTaskTypes: (state, action) => {
      state.taskTypes = action.payload;
    },
    setTaskTypeAttributes: (state, action) => {
      state.taskTypeAttributes = action.payload;
    },

    setSelectedTaskTypeId: (state, action) => {
      state.selectedTaskId = action.payload;
    },

    setTaskTypeName: (state, action) => {
      state.taskTypeName = action.payload;
    },

    setConnections: (state, action) => {
      state.connections = action.payload;
    },
    setSelectedConnection: (state, action) => {
      state.selectedConnection = action.payload;
    },
    setTaskAttribValues: (state, action) => {
      state.taskAttribValues = action.payload;
    },
    resetState: () => initialState,
    setTaskFormDefaultValues: (state, action) => {
      const { payload } = action;
      state.name = payload.name;
      state.description = payload.description;
      state.taskTypeName = payload.taskTypeName;
      state.selectedTaskId = payload.taskTypeId;
      state.taskTypeAttributes = payload.taskTypeAttributes;

      const obj = {};
      payload.taskTypeAttributes.forEach((taskAttrib) => {
        obj[taskAttrib.name] = taskAttrib.value;
      });

      state.taskAttribValues = obj;
    },
    // Gsheet file and range dynamic selector
    setGSheetFileAndRangeValue: (state, { payload }) => {
      const { idx, value, name } = payload;
      const newVal = state.gSheetFileAndRangValue.map((el, index) => {
        if (idx === index) {
          const newEl = { ...el };
          newEl[name] = value;
          return newEl;
        }
        return el;
      });

      state.gSheetFileAndRangValue = newVal;
    },
    setGSheetFileAndRangeDefaultValue: (state, action) => {
      state.gSheetFileAndRangValue = action.payload;
    },
    addGsheetFileAndRangeValueRow: (state) => {
      const updated = [
        ...state.gSheetFileAndRangValue,
        { Sheet: "", File: "", rowindex: "", colindex: "" },
      ];
      state.gSheetFileAndRangValue = updated;
    },
    deleteGsheetFileAndRangeValueRow: (state) => {
      if (state.gSheetFileAndRangValue.length === 1) return;
      state.gSheetFileAndRangValue.pop();
    },
    // Gsheet range and value dynamic selector
    setGsheetRangeAndValue: (state, { payload }) => {
      const { idx, value, name } = payload;
      const newVal = state.gSheetRangeAndValue.map((el, index) => {
        if (idx === index) {
          const newEl = { ...el };
          newEl[name] = value;
          return newEl;
        }
        return el;
      });

      state.gSheetRangeAndValue = newVal;
    },
    setGsheetRangeAndValueDefaultValue: (state, action) => {
      state.gSheetRangeAndValue = action.payload;
    },
    addGsheetRangeAndValueRow: (state) => {
      const updated = [...state.gSheetRangeAndValue, { range: "", value: "" }];
      state.gSheetRangeAndValue = updated;
    },
    deleteGsheetRangeAndValueRow: (state) => {
      if (state.gSheetRangeAndValue.length === 1) return;
      state.gSheetRangeAndValue.pop();
    },
    addColumnNamesAndValuesRow: (state) => {
      const updated = [
        ...state.addColumnNamesAndValues,
        { columnName: "", value: "" },
      ];
      state.addColumnNamesAndValues = updated;
    },
    deleteColumnNamesAndValuesRow: (state) => {
      if (state.addColumnNamesAndValues.length === 1) return;
      state.addColumnNamesAndValues.pop();
    },
    setColumnNamesAndValues: (state, { payload }) => {
      const { idx, value, name } = payload;
      const newVal = state.addColumnNamesAndValues.map((el, index) => {
        if (idx === index) {
          const newEl = { ...el };
          newEl[name] = value;
          return newEl;
        }
        return el;
      });

      state.addColumnNamesAndValues = newVal;
    },
    setColumnNamesAndValuesDefaultValue: (state, action) => {
      state.addColumnNamesAndValues = action.payload;
    },
  },
});

export const {
  setTaskName,
  setTaskDescription,
  setAddTaskLoader,
  setTaskTypes,
  setTaskTypeAttributes,
  setSelectedTaskTypeId,
  setTaskTypeName,
  setConnections,
  setSelectedConnection,
  setTaskAttribValues,
  resetState,
  setTaskFormDefaultValues,
  setGSheetFileAndRangeValue,
  addGsheetFileAndRangeValueRow,
  deleteGsheetFileAndRangeValueRow,
  setGSheetFileAndRangeDefaultValue,
  setGsheetRangeAndValue,
  setGsheetRangeAndValueDefaultValue,
  addGsheetRangeAndValueRow,
  deleteGsheetRangeAndValueRow,
  addColumnNamesAndValuesRow,
  deleteColumnNamesAndValuesRow,
  setColumnNamesAndValues,
  setColumnNamesAndValuesDefaultValue,
} = taskFormSlice.actions;

export default taskFormSlice.reducer;
