import { Grid, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTaskAttribValues } from "../../../app/features/taskFormSlice/taskFormSlice";
import { getAnaplanWorkspaces } from "../../../api/others";

/**
 * Component fetches dropdpown options from server and renders the workspaces available
 * After a workspace is selected corresponding models are displayed
 * After model is selected corresponding action types are displayed
 * After action type is selected corresponding actions are displayed
 */
const AnaplanDropdowns = ({ actionsTypes }) => {
  const [workspaces, setWorkspaces] = useState([]);

  // redux state
  const { taskAttribValues, selectedConnection, connections } = useSelector(
    (state) => state.taskForm
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const getWorkspaces = async () => {
      const connectionName = connections.find(
        (el) => el._id === selectedConnection
      )?.name;
      const { data } = await getAnaplanWorkspaces(connectionName);

      setWorkspaces(data);
    };

    getWorkspaces();
  }, [selectedConnection]);

  const handleChange = (e) => {
    const obj = {};
    obj[e.target.name] = e.target.value;

    dispatch(
      setTaskAttribValues({
        ...taskAttribValues,
        ...obj,
      })
    );
  };

  const getModelsForWorkspace = (workspaceId) => {
    const found = workspaces.find((el) => el.id === workspaceId);
    return found?.models;
  };

  const getActionsForModel = (workspaceId, modelId, actionType) => {
    const foundWorkspace = workspaces.find((el) => el.id === workspaceId);

    const foundModel = foundWorkspace?.models?.find((el) => el.id === modelId);

    if (foundModel) {
      return foundModel[actionType];
    }
  };

  return (
    <Grid item>
      {
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              name="anaplanWorkspaceId"
              label="Select Workspace"
              select
              value={taskAttribValues.anaplanWorkspaceId}
              onChange={(e) => {
                const obj = {};
                obj[e.target.name] = e.target.value;
                // reset other selections
                obj["anaplanModelId"] = "";
                obj["anaplanActionType"] = "";
                obj["anaplanActionId"] = "";

                dispatch(
                  setTaskAttribValues({
                    ...taskAttribValues,
                    ...obj,
                  })
                );
              }}
              required
            >
              {workspaces?.map((workspace) => (
                <MenuItem value={workspace?.id} key={workspace?.id}>
                  {workspace?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {taskAttribValues.anaplanWorkspaceId && (
            <Grid item xs={3}>
              <TextField
                fullWidth
                name="anaplanModelId"
                label="Select Model"
                select
                value={taskAttribValues.anaplanModelId}
                onChange={(e) => {
                  const obj = {};
                  obj[e.target.name] = e.target.value;
                  // reset other selections
                  obj["anaplanActionType"] = "";
                  obj["anaplanActionId"] = "";

                  dispatch(
                    setTaskAttribValues({
                      ...taskAttribValues,
                      ...obj,
                    })
                  );
                }}
                required
              >
                {getModelsForWorkspace(
                  taskAttribValues.anaplanWorkspaceId
                )?.map((model) => (
                  <MenuItem value={model.id} key={model.id}>
                    {model.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {taskAttribValues.anaplanModelId && (
            <Grid item xs={3}>
              <TextField
                fullWidth
                name="anaplanActionType"
                label="Select Action Type"
                select
                value={taskAttribValues.anaplanActionType}
                onChange={(e) => {
                  const obj = {};
                  obj[e.target.name] = e.target.value;
                  // reset other selections
                  obj["anaplanActionId"] = "";

                  dispatch(
                    setTaskAttribValues({
                      ...taskAttribValues,
                      ...obj,
                    })
                  );
                }}
                required
              >
                {actionsTypes.map((action) => (
                  <MenuItem value={action} key={action}>
                    {action}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {taskAttribValues.anaplanActionType && (
            <Grid item xs={3}>
              <TextField
                fullWidth
                name="anaplanActionId"
                label="Select Option"
                required
                select
                value={taskAttribValues.anaplanActionId}
                onChange={handleChange}
              >
                {getActionsForModel(
                  taskAttribValues.anaplanWorkspaceId,
                  taskAttribValues.anaplanModelId,
                  taskAttribValues.anaplanActionType
                )?.map((actionOption) => (
                  <MenuItem value={actionOption.id} key={actionOption.id}>
                    {actionOption.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>
      }
    </Grid>
  );
};

export default AnaplanDropdowns;
