import { toast, Bounce } from "react-toastify";

export const roles = [
  { value: "admin", label: "Admin" },
  { value: "user", label: "User" },
];
export const DEFAULT_ERROR_NOTIFICATION = "Something went wrong!";

export const TOASTR_OPTIONS = {
  // CUSTUM TOSTIFY
  position: toast.POSITION.TOP_RIGHT,
  transition: Bounce,
  // theme: 'colored',
};

//TODO make better implementation? maybe use connectionTypeId to identify
export const ignoreConnectionWarningForTaskTypes = [
  "14",
  "15",
  "16",
  "17",
  "21",
  "26",
  "31",
  "32",
  "33",
];

export const timezones = [
  "Africa/Abidjan",
  "Africa/Lagos",
  "Africa/Maputo",
  "Africa/Nairobi",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Pacific/Samoa",
  "US/Hawaii",
  "US/Alaska",
  "US/Pacific",
  "US/Mountain",
  "US/Central",
  "America/Mexico_City",
  "US/Eastern",
  "America/Bogota",
  "America/Puerto_Rico",
  "America/Halifax",
  "America/Buenos_Aires",
  "Canada/Newfoundland",
  "Brazil/DeNoronha",
  "Atlantic/Cape_Verde",
  "Europe/London",
  "Europe/Amsterdam",
  "Europe/Athens",
  "Europe/Moscow",
  "Asia/Tehran",
  "Asia/Dubai",
  "Asia/Kabul",
  "Asia/Karachi",
  "Asia/Kolkata",
  "Asia/Dhaka",
  "Asia/Yangon",
  "Asia/Jakarta",
  "Asia/Hong_Kong",
  "Asia/Tokyo",
  "Australia/North",
  "Australia/South",
  "Australia/Brisbane",
  "Australia/Sydney",
  "Asia/Sakhalin",
  "Pacific/Norfolk",
  "Pacific/Auckland",
  "Pacific/Kanton",
  "Pacific/Kiritimati",
];

export const drawerWidth = 310; // NAV SIDE BAR WIDTH ON ACTIVE STATE
export const LogoutText = "Logout";
export const AdminText = "Configurations";
export const RefreshText = "Refresh Data";
export const ClearLogs = "Clear Logs";
export const fileExplorer = "File Explorer";
